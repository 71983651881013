import * as React from "react";
import AssetPage from "../../components/asset-page";

const EthereumPage = () => (
  <AssetPage
    hideFeatureImage
    asset="Ethereum"
    ticker="ETH"
    description={[
      "Introduced in 2015 by Vitalik Buterin, Ethereum revolutionized the blockchain space by extending its functionalities beyond just transactions.",
      "Unlike Bitcoin, which primarily serves as a digital currency, Ethereum facilitates peer-to-peer contracts and applications via its currency, Ether (ETH).",
      "Developers utilize Ethereum's platform and developmental language to create distributed applications (dApps) that execute smart contracts, enhancing functionalities beyond currency exchange.",
    ]}
    features={[
      {
        title: "Smart Contracts",
        text: "Ethereum introduces smart contracts: self-executing agreements with terms directly coded into lines of code, removing the need for intermediaries. These contracts automatically enact and validate agreements, ensuring secure and transparent transactions and have applications in various fields, including finance and content creation.",
      },
      {
        title: "Decentralized Applications (dApps)",
        text: "The platform supports decentralized applications (dApps), running on peer-to-peer networks instead of centralized servers. dApps on Ethereum offer enhanced stability and resistance to hacking, adhering to principles of transparency and global accessibility, providing solutions that anyone with internet access can utilize.",
      },
      {
        title: "Decentralized Finance (DeFi)",
        text: "Decentralized Finance (DeFi) is a vital innovation enabled by Ethereum. It uses blockchain and crypto assets to recreate and enhance traditional financial systems, like lending and trading, without centralized authorities. Ethereum’s DeFi applications aim to democratize finance, facilitating a globally accessible financial system without traditional banking gatekeepers.",
      },
      {
        title: "ERC-20 Tokens",
        text: "Ethereum facilitates the creation of ERC-20 tokens, setting a standard for tokens issued via its smart contracts. This standard ensures compatibility with existing blockchain infrastructure and interaction among other ERC-20 tokens, enabling utility and governance tokens in decentralized applications and has significantly impacted the broader blockchain technology’s utility and application.",
      },
    ]}
  />
);

export default EthereumPage;
